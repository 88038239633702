<!-- Finder expansible de Expedientes Traslados -->

<template>
  <div class="exp_M_presSup_XM" v-if="schema">      
    <v-sheet :elevation="4">
    
      <!-- Botones Mto -->
      <div class="conflex contenedor" style="justify-content:space-between;width:220px">
        <div>
          <!-- Mto -->            
          <!-- <v-btn
            v-bind="$cfg.btra_cfg.standard"  
            style="margin-left:10px"
            title="Guardar"
            @click="guardar">
              <v-icon dark>{{ "mdi-content-save" }}</v-icon>
          </v-btn>    -->  
          <btramto          
            :btra_permisos="schema.btra"
            :btra_particular="Entorno.btra"
            btra_modulo="xm"
            :estado="estado"
            @onEvent="event_btra_Mto">                
          </btramto>
        </div>

        <div v-if="estado!='nuevo'">
          <!-- Digitalización -->
          <fras_Digi            
            :padre="stName" 
            accion=""     
            :accionRow="accionRow"
            :tipo="2"
            :disabled="accionRow.digf_usu!= ''"
            @reload="$emit('reload')">                    
            
          </fras_Digi>
        </div>
      </div>

      <!-- Controles del Mto -->
      <div class="contenedor">    

        <div class="columna">
          <div class="conflex">
            <vllabel
              style="flex: 0 0 15%"
              label="Id"
              :txt="schema.ctrls.id.value">              
            </vllabel>

            <vllabel
              style="flex: 0 0 35%"
              label="Fecha"
              :txt="schema.ctrls.fhhr.value | fechahr">             
            </vllabel>

            <vllabel
              style="flex: 0 0 25%"
              label="Asiento"
              :txt="schema.ctrls.conta_asi.value">              
            </vllabel>

            <vllabel
              style="flex: 0 0 25%"
              label="Usuario"
              :txt="schema.ctrls.usu.value">           
            </vllabel>
          </div>

          <compMultiple                 
            :ctrl="schema.ctrls.cta_id"
            :schema="schema"                  
            :disabled="!is_edit">
          </compMultiple>

          <v-textarea
            v-bind="$textarea"
            v-model="schema.ctrls.des.value"
            :label="schema.ctrls.des.label"
            rows="1"
            no-resize
            :disabled="!is_edit">  
          </v-textarea>

          <div class="conflex">           
            <v-text-field
              style="flex: 0 0 33%"              
              v-bind="$input"
              v-model="schema.ctrls.fra_doc.value"
              label="Factura"
              :disabled="!is_edit">
            </v-text-field>

            <compfecha style="flex: 0 0 33%" :schema="schema.ctrls.fra_fh" :edicion="is_edit" /> 

            <vllabel              
              style="flex: 0 0 33%"
              stilot="font-size:16px;font-weight:bold;text-align:right;color:green"
              label="Total"              
              :txt="schema.ctrls.tot.value|num(2)">              
            </vllabel>          
          </div>         

          <div class="conflex" style="padding-top:10px;justify-content:flex-end">
            <div class="columna">
              <div class="cab">Base</div> 
              <v-sheet :elevation="4"  style="padding:5px">        
                <v-input_n    
                  style="margin-top:43px"                            
                  v-model="schema.ctrls.base1.value"
                  :disabled="!is_edit"
                  @blur="calcular">              
                </v-input_n>

                <v-input_n                            
                  v-model="schema.ctrls.base2.value"
                  :disabled="!is_edit"
                  @blur="calcular">          
                </v-input_n>

                <v-input_n                            
                  v-model="schema.ctrls.base3.value"
                  :disabled="!is_edit"
                  @blur="calcular">                 
                </v-input_n>

              </v-sheet>
            </div>

            <div class="columna" style="margin-left:20px;width:120px">
              <div class="cab">Iva</div> 
              <v-sheet :elevation="4"  style="padding:5px">
                <v-input_n 
                  style="margin-top:43px"                           
                  v-model="schema.ctrls.ivapor1.value"
                  :disabled="!is_edit"
                  @blur="calcular"
                  simbolo="%">                 
                </v-input_n>

                <v-input_n                            
                  v-model="schema.ctrls.ivapor2.value"
                  :disabled="!is_edit"
                  @blur="calcular"
                  simbolo="%">               
                </v-input_n>

                <v-input_n                            
                  v-model="schema.ctrls.ivapor3.value"
                  :disabled="!is_edit"
                  simbolo="%"
                  @blur="calcular">                 
                </v-input_n>

                <v-input_n          
                  label="I.R.P.F"                  
                  v-model="schema.ctrls.irpfpor.value"
                  :disabled="!is_edit"
                  @blur="calcular"
                  simbolo="%">                
                </v-input_n>

              </v-sheet>
            </div>
                
            <div class="columna" style="margin-left:20px;">
              <div class="cab">Importe</div> 
              <v-sheet :elevation="4" style="padding:5px">
                <v-input_n                            
                  v-model="schema.ctrls.sup.value"
                  label="Suplidos"
                  :disabled="!is_edit"
                  @blur="calcular">               
                </v-input_n>

                <v-input_n                            
                  v-model="schema.ctrls.iva1.value"
                  :disabled="true">              
                </v-input_n>

                <v-input_n                            
                  v-model="schema.ctrls.iva2.value"
                  :disabled="true">                   
                </v-input_n>

                <v-input_n                            
                  v-model="schema.ctrls.iva3.value"
                  :disabled="true">     
                </v-input_n>

                <v-input_n                            
                  v-model="schema.ctrls.irpf.value"
                  :disabled="true">                  
                </v-input_n>
              </v-sheet>
            </div>
          </div>
        </div>                                        
      </div>
    </v-sheet>       
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js"; 
  import plugs from "@/common/general_plugs";
  const compMultiple = () => plugs.groute("compMultiple.vue", "comp"); 
  const btramto = () => plugs.groute("btramto_new.vue", "comp"); 
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
  const fras_Digi = () => plugs.groute("fras_Digi.vue", "comp"); 
     
  export default {
    mixins: [mixinMto],  
    components: { compMultiple, btramto, compfecha, fras_Digi },
    props: {
      padre: { type:String, default: '' },    
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}}, 
      auxRecord: { type:Object, default: ()=> {}}, 
      disparo: { type:Boolean, default:false }, 
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'exp_presSup_M',
        stName:'stMexp_presSup_M',
        disparoFinder:false,
        
        // Pendiente: ver donde ponemos campo_relacionado
        //campo_relacionado: "prescab_id",
      };
    },  
    

    methods: {
      ini_data() { 
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  
        
        // entorno        
        this.Entorno.header.header= false;          
        //this.Entorno.btra="XM"; 
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardXM)); 
      },


      //
      record_cargado() {           
        
        if (this.schema.ctrls.usu.value!='') {
          this.schema.ctrls.usu.value= this.usu;
          this.schema.ctrls.fra_fh.value= this.actualDate();
        }

        //si contabilizado no edicion, ni borrado
        this.btnSet(2,{view:true, disabled: (this.accionRow.conta_asi>0) });
        this.btnSet(3,{view:true, disabled: (this.accionRow.conta_asi>0) });
        
      },


      guardar_before() {
        if (!this.schema.ctrls.fra_doc.value) { this.$root.$alert.open('Debe indicar un Nº de Factura', 'error'); return; }
        if (!this.schema.ctrls.fra_fh.value) { this.$root.$alert.open('Debe indicar una Fecha de Factura', 'error'); return; }
        if (!this.schema.ctrls.tot.value) { this.$root.$alert.open('Debe tener un Importe', 'error'); return; }
        if (this.schema.ctrls.cta_id.value== 0) { this.$root.$alert.open('Debe indicar un Proveedor', 'error'); return; }
        
        /* let importe= !this.accionRow.tot? 0 : this.accionRow.tot;
        if ((Number(this.auxRecord.supfra) - Number(importe) + Number(this.schema.ctrls.tot.value))> Number(this.auxRecord.sup)) { 
          this.$root.$alert.open('Importe superior al total del Suplido', 'error'); 
          return; 
        } */
      
        this.schema.ctrls.prescab_id.value= this.auxRecord.id;        
        return true;
      },

      /* 
      ///////////////////////// llamada desde el propio api php
      //
      guardar_fin() {       
        this.suplidos_calcular();
        //contabilizar el suplido so
      },

      //
      borrar_fin() {
        this.suplidos_calcular();
      },

      // 
      async suplidos_calcular() {
        let args={ 
          tipo:"fnc",
          accion: "facturas",
          fn_args:{
            accion:"suplidos_calcular",
            id:this.auxRecord.id
          }
        };
        
        let apiResult= await this.$store.dispatch("ajaxRequest", { args: args });        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // actualizo record
        //await this.$store.commit('stFfras_Exp_detalle/update_record', { opcion: 'guardar', record: apiResult.r[0], id: apiResult.r[0].id });           
        //this.$store.state.reload_fras_exp_detalle= !this.$store.state.reload_fras_exp_detalle;
                
        // recalculo totales compras / ventas (fn en mixinCtrls)
        //let stExp_detalle= this.$store.state['stFfras_Exp_detalle'];
        //this.recalcula_fras(stExp_detalle.schema.ctrls, stExp_detalle.records);      
      },

      */

      //
      calcular() {        
        let X=this.schema.ctrls;

        X.sup.value=this.round(X.sup.value);
        X.base1.value=this.round(X.base1.value);
        X.ivapor1.value=this.round(X.ivapor1.value);
        X.iva1.value= (X.ivapor1.value>0 ? this.round(X.base1.value * X.ivapor1.value / 100) : 0);
        X.base2.value=this.round(X.base2.value);
        X.ivapor2.value=this.round(X.ivapor2.value);
        X.iva2.value= (X.ivapor2.value>0 ? this.round(X.base2.value * X.ivapor2.value / 100) : 0);
        X.base3.value=this.round(X.base3.value);
        X.ivapor3.value=this.round(X.ivapor3.value);
        X.iva3.value= (X.ivapor3.value>0 ? this.round(X.base3.value * X.ivapor3.value / 100) : 0);
      
        let base=X.base1.value+X.base2.value+X.base3.value;
        let iva=X.iva1.value+X.iva2.value+X.iva3.value;
        X.irpfpor.value=this.round(X.irpfpor.value);
        X.irpf.value= (X.irpfpor.value>0 ? this.round(base * X.irpfpor.value / 100) : 0);

        //this.calculaTot();
        X.tot.value=base + iva + X.sup.value - X.irpf.value;
      },

      /* calculaTot() {        
        let sup= this.round(this.schema.ctrls.sup.value);
        let base1= this.round(Number(this.schema.ctrls.base1.value));
        let iva1= this.schema.ctrls.iva1.value;
        let base2= this.round(this.schema.ctrls.base2.value);
        let iva2= this.schema.ctrls.iva2.value;
        let base3= this.round(this.schema.ctrls.base3.value);
        let iva3= this.schema.ctrls.iva3.value;
        let irpf= this.schema.ctrls.irpf.value;

        var t= sup + base1 + iva1 + base2 + iva2 + base3 + iva3 - irpf;
        this.schema.ctrls.tot.value = t;
      }, */


      //
      round(n, d=2) {                
        let r=Number(Math.round(n*Math.pow(10,d))/Math.pow(10,d).toFixed(d));       
        return r;
      }    
     
    }
};
</script>
